import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Client Portal | Wavedropper
			</title>
			<meta name={"description"} content={"Pattern of Success,\nMapped for your Business"} />
			<meta property={"og:title"} content={"Client Portal | Wavedropper"} />
			<meta property={"og:description"} content={"Pattern of Success,\nMapped for your Business"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final-green.png?v=2024-04-24T16:57:01.241Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65f854bd8e2e8e002179c15f/images/logo-final.svg?v=2024-04-24T16:41:50.528Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65f854bd8e2e8e002179c15d"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});